import { ChangeDetectionStrategy, Component, inject, OnInit, signal, WritableSignal } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { animate, style, transition, trigger } from '@angular/animations';
import { FastSvgComponent } from '@owain/fast-svg/fast-svg.component';
import { SvgRegistry } from '@owain/fast-svg/svg-registry.service';
import { JsonLdService } from '../../services/seo/json-ld.service';

@Component({
  selector: 'app-scroll-top-top',
  templateUrl: './scroll-to-top.component.html',
  styleUrl: './scroll-to-top.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '(window:scroll)': 'onWindowScroll()',
  },
  animations: [
    trigger('scrollToTop', [
      transition(':enter', [
        style({
          opacity: 0,
        }),
        animate(
          '250ms ease',
          style({
            opacity: 1,
          })
        ),
      ]),
      transition(':leave', [
        animate(
          '250ms ease',
          style({
            opacity: 0,
          })
        ),
      ]),
    ]),
  ],
  imports: [FastSvgComponent],
})
export class ScrollToTopComponent implements OnInit {
  public windowScrolled: WritableSignal<boolean> = signal(false);
  private readonly svgRegistry: SvgRegistry = inject(SvgRegistry);
  private readonly document: Document = inject(DOCUMENT);
  private readonly jsonLdService: JsonLdService = inject(JsonLdService);

  ngOnInit(): void {
    this.svgRegistry.fetchSvgs('icons/arrowup');

    this.jsonLdService.addHeroiconImage('icons/arrowup');

    this.jsonLdService.inject();
  }

  public scrollToTop(): void {
    this.document.documentElement.scrollTo({ top: 0, behavior: 'smooth' });
  }

  protected onWindowScroll(): void {
    if (this.document.documentElement.scrollTop > 0 || this.document.body.scrollTop > 0) {
      this.windowScrolled.set(true);
    } else if (
      this.windowScrolled() &&
      (this.document.documentElement.scrollTop === 0 || this.document.body.scrollTop === 0)
    ) {
      this.windowScrolled.set(false);
    }
  }
}
