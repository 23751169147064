import { inject, Injectable, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { SERVER_REQUEST_TOKEN } from '@owain/universal-fastify-engine/interface';

@Injectable({
  providedIn: 'root',
})
export class CookieService {
  private readonly request: Request = inject(SERVER_REQUEST_TOKEN, { optional: true });
  private readonly platformId: Object = inject(PLATFORM_ID);
  private readonly document: Document = inject(DOCUMENT);

  public setItem(key: string, value: string): void {
    if (isPlatformBrowser(this.platformId)) {
      const millisecondsInHour = 3_600_000;
      const hoursInMonth = 720;

      const name = encodeURIComponent(key);
      const date = new Date();
      date.setTime(date.getTime() + hoursInMonth * millisecondsInHour);
      this.document.cookie = `${name}=${encodeURIComponent(value)};expires=${date.toUTCString()};path=/`;
    }
  }

  public get(name: string): string {
    const cookies: {   [p: string]: string } | {   cookies: any } | null = this.getAll();

    if (cookies == null) {
      return '';
    } else {
      // @ts-ignore
      return cookies[name] || '';
    }
  }

  private getAll(): { [p: string]: string } | { cookies: any } | null {
    if (isPlatformServer(this.platformId)) {
      try {
        // @ts-ignore
        return typeof this.request.headers['cookie'] === 'string'
          ? // @ts-ignore
            this.safeDecodeURIComponent(this.request.headers['cookie'])
              .split(';')
              .reduce((cookies: {}, cookie:  string) => {
                const val: string[] = cookie.split('=');
                return { ...cookies, [val[0].trim()]: val[1] };
              }, {})
          : // @ts-ignore
            { cookies: this.request.headers['cookie'].map((cookie: any): any => this.safeDecodeURIComponent(cookie)) };
      } catch {
        return null;
      }
    } else if (isPlatformBrowser(this.platformId)) {
      return this.parseCookieString(this.document.cookie || '');
    }

    return null;
  }

  private isNil(obj: unknown): obj is undefined | null {
    return obj === undefined || obj === null;
  }

  private safeDecodeURIComponent(str: string): string {
    try {
      return decodeURIComponent(str);
    } catch (e) {
      return str;
    }
  }

  private parseCookieString(currentCookieString: string): { [key: string]: string } {
    let lastCookies: { [key: string]: string } = {};
    let lastCookieString: string = '';
    let cookieArray: string[];
    let cookie: string;
    let i: number;
    let index: number;
    let name: string;
    if (currentCookieString !== lastCookieString) {
      lastCookieString = currentCookieString;
      cookieArray = lastCookieString.split(';');
      lastCookies = {};
      for (i = 0; i < cookieArray.length; i++) {
        cookie = cookieArray[i];
        index = cookie.indexOf('=');
        if (index > 0) {
          // ignore nameless cookies
          name = this.safeDecodeURIComponent(cookie.substring(0, index).trim());
          // the first value that is seen for a cookie is the most
          // specific one.  values for the same cookie name that
          // follow are for less specific paths.
          if (this.isNil(lastCookies[name])) {
            lastCookies[name] = this.safeDecodeURIComponent(cookie.substring(index + 1).trim());
          }
        }
      }
    }
    return lastCookies;
  }
}
