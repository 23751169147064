import {
  afterNextRender,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  OnInit,
  PendingTasks,
  Renderer2,
  signal,
  Signal,
  TemplateRef,
  viewChild,
  ViewContainerRef,
  WritableSignal,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterLink, RouterOutlet } from '@angular/router';
import { TranslocoPipe } from '@jsverse/transloco';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { NotifierContainerComponent } from '@owain/notifier/lib/components/notifier-container.component';
import { ScrollToTopComponent } from './components/scroll-top-top/scroll-to-top.component';
import { LinkDirective } from '@owain/quicklink/link.directive';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { SwUpdateService } from './services/sw-update.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { filter, tap } from 'rxjs/operators';
import { CAPTCHA_V3_SERVICE } from './tokens/injection.tokens';
import { RecaptchaV3Interface } from '@owain/recaptcha/recaptcha-v3.interface';
import { CookiePreferencesService } from './services/cookie-preferences.service';
import { LanguageFlagPipe } from './pipes/language-flag.pipe';
import { LanguageShortPipe } from './pipes/language-short.pipe';
import { LanguagePipe } from './pipes/language-switcher.pipe';
import { FastSvgComponent } from '@owain/fast-svg/fast-svg.component';
import { PrefetchDirective } from './directives/prefetch.directive';
import { ApplicationUiStore } from './store/application-ui/application-ui.store';
import { UserStore } from './store/user/user.store';
import { PrefetchService } from './services/prefetch.service';
import { SvgRegistry } from '@owain/fast-svg/svg-registry.service';
import { JsonLdService } from './services/seo/json-ld.service';
import { ToasterService } from '@owain/notifier/lib/services/toaster.service';
import { AppFooterComponent } from './components/app-footer/app-footer.component';
import { DOCUMENT } from '@angular/common';
import { ClickOutside } from 'ngxtension/click-outside';
import { SeoTagService } from '@owain/seo/services/seo-tag.service';
import { LocalizeRouterPipe } from '@owain/transloco-utils/localize-router.pipe';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    RouterOutlet,
    TranslocoPipe,
    DropdownComponent,
    RouterLink,
    NotifierContainerComponent,
    ScrollToTopComponent,
    LinkDirective,
    LocalizeRouterPipe,
    LanguageFlagPipe,
    LanguageShortPipe,
    LanguagePipe,
    FastSvgComponent,
    PrefetchDirective,
    AppFooterComponent,
    ClickOutside,
  ],
  animations: [
    trigger('menuBackdrop', [
      transition(':enter', [
        style({
          opacity: 0,
        }),
        animate(
          '500ms ease',
          style({
            opacity: 1,
          })
        ),
      ]),
      transition(':leave', [
        animate(
          '500ms ease',
          style({
            opacity: 0,
          })
        ),
      ]),
    ]),

    trigger('menu', [
      transition(':enter', [
        style({
          transform: 'translateX(-100%)',
        }),
        animate(
          '250ms ease-in-out',
          style({
            transform: 'translateX(0%)',
          })
        ),
      ]),
      transition(':leave', [
        animate(
          '250ms ease-in-out',
          style({
            transform: 'translateX(-100%)',
          })
        ),
      ]),
    ]),

    trigger('themeIcon', [
      state(
        'moon',
        style({
          transform: 'rotate(0deg)',
        })
      ),
      state(
        'sun',
        style({
          transform: 'rotate(90deg)',
        })
      ),
      transition('moon => sun', [animate('250ms')]),
      transition('sun => moon', [animate('250ms')]),
    ]),
  ],
})
export class AppComponent implements OnInit {
  private readonly svgRegistry: SvgRegistry = inject(SvgRegistry);
  private readonly notificationTmpl: Signal<TemplateRef<any>> = viewChild.required('notification', {
    read: TemplateRef,
  });
  private readonly document: Document = inject(DOCUMENT);
  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  private readonly router: Router = inject(Router);
  public readonly currentUrl: WritableSignal<string> = signal(this.router.url);
  private readonly viewContainerRef: ViewContainerRef = inject(ViewContainerRef);
  private readonly changeDetectorRef: ChangeDetectorRef = inject(ChangeDetectorRef);
  private readonly swUpdateService: SwUpdateService = inject(SwUpdateService);
  private readonly reCaptchaV3Service: RecaptchaV3Interface = inject(CAPTCHA_V3_SERVICE);
  private readonly toasterService: ToasterService = inject(ToasterService);
  private readonly prefetchService: PrefetchService = inject(PrefetchService);
  private readonly applicationUiStore: InstanceType<typeof ApplicationUiStore> = inject(ApplicationUiStore);
  public readonly language: Signal<string | null> = this.applicationUiStore.language;
  public readonly theme: Signal<boolean> = this.applicationUiStore.theme;
  public readonly menuOpen: Signal<boolean> = this.applicationUiStore.mainMenu;
  public readonly hideLayout: Signal<boolean> = this.applicationUiStore.hideLayout;
  private readonly cookiePreferencesService: CookiePreferencesService = inject(CookiePreferencesService);
  private readonly userStore: InstanceType<typeof UserStore> = inject(UserStore);
  public readonly authenticated: Signal<boolean> = this.userStore.authenticated;
  public readonly isAdmin: Signal<boolean> = this.userStore.isAdmin;
  private readonly jsonLdService: JsonLdService = inject(JsonLdService);
  private readonly seoTagService: SeoTagService = inject(SeoTagService);
  private readonly pendingTasks: PendingTasks = inject(PendingTasks);
  private readonly renderer: Renderer2 = inject(Renderer2);

  constructor() {
    afterNextRender(() => {
      this.cookiePreferencesService.checkPreferences(this.viewContainerRef);
    });
  }

  ngOnInit(): void {
    this.router.events
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(ev => ev instanceof NavigationEnd),
        tap(() => this.currentUrl.set(this.router.url))
      )
      .subscribe();

    this.svgRegistry.fetchSvgs(
      'icons/menu-close',
      'icons/menu-hamburger',
      'logo/archery-events',
      'icons/star',
      'icons/settings',
      'icons/sign-out',
      'icons/sign-in',
      'icons/register',
      'flags/us',
      'flags/nl',
      'icons/checkcircle',
      'icons/infocircle',
      'icons/xcircle',
      'icons/exclamationcircle',
      'icons/x'
    );

    this.jsonLdService.addImage(
      {
        contentUrl: 'https://archery-events.com/assets/svg/logo/archery-events.svg',
      },
      {
        creditText: 'Archery events',
        copyrightNotice: 'Archery events',
      },
      {
        '@type': 'Organization',
        name: 'Archery events',
      }
    );

    this.jsonLdService.addHeroiconImage('icons/menu-close');
    this.jsonLdService.addHeroiconImage('icons/menu-hamburger');
    this.jsonLdService.addHeroiconImage('icons/star');
    this.jsonLdService.addHeroiconImage('icons/settings');
    this.jsonLdService.addHeroiconImage('icons/sign-out');
    this.jsonLdService.addHeroiconImage('icons/sign-in');
    this.jsonLdService.addHeroiconImage('icons/register');
    this.jsonLdService.addHeroiconImage('flags/us');
    this.jsonLdService.addHeroiconImage('flags/nl');
    this.jsonLdService.addHeroiconImage('icons/checkcircle');
    this.jsonLdService.addHeroiconImage('icons/infocircle');
    this.jsonLdService.addHeroiconImage('icons/xcircle');
    this.jsonLdService.addHeroiconImage('icons/exclamationcircle');
    this.jsonLdService.addHeroiconImage('icons/x');

    this.initialSeoTags();

    this.jsonLdService.inject();

    this.reCaptchaV3Service.noop();

    this.swUpdateService.update(this.viewContainerRef);

    this.toasterService
      .notifier(this.notificationTmpl())
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap(() => this.changeDetectorRef.markForCheck())
      )
      .subscribe();
  }

  public outsideMenuClick() {
    this.toggleMenu(true);
  }

  public switchLang(language: string) {
    this.applicationUiStore.switchLanguage({
      language: language,
      save: true,
    });
  }

  public switchTheme() {
    this.applicationUiStore.switchTheme({
      dark: !this.theme(),
    });
  }

  public toggleMenu(force: boolean = false): void {
    this.applicationUiStore.toggleMenu(force);
  }

  public onClickDismiss(id: string): void {
    this.toasterService.hide(id);
  }

  public prefetchAdmin() {
    this.prefetchService.prefetchAdmin();
  }

  private initialSeoTags(): void {
    const taskCleanup: () => void = this.pendingTasks.add();

    const url: string = this.router.url;
    let urlLang = 'en';

    if (url.startsWith('/nl')) {
      urlLang = 'nl';
    } else if (url.startsWith('/en')) {
      urlLang = 'en';
    }

    if (urlLang && this.applicationUiStore.language() !== urlLang) {
      this.applicationUiStore.switchLanguage({
        language: urlLang,
        save: true,
      });
    }

    this.renderer.setAttribute(this.document.querySelector('#manifest'), 'href', `manifest.${urlLang}.webmanifest`);

    let route: ActivatedRoute = this.router.routerState.root;
    while (route!.firstChild) {
      route = route.firstChild;
    }

    const localizedData = route.snapshot.data[<string>urlLang];

    if (localizedData) {
      const titleParam = route.snapshot.data['titleParam']
        ? route.snapshot.params[route.snapshot.data['titleParam']]
        : null;

      let title = localizedData.title;
      const breadcrumbs = localizedData.breadcrumbs;

      if (breadcrumbs) {
        this.jsonLdService.clearData(urlLang);
        this.jsonLdService.setBreadcrumbs(breadcrumbs, urlLang, route.snapshot.params);
        this.jsonLdService.inject();
      } else {
        this.jsonLdService.clearDataInject(urlLang);
      }

      if (titleParam) {
        title += ` - ${this.titleCase(titleParam)}`;
      }

      this.seoTagService.setData({
        title: title,
        // keywords:
        //   urlLang == 'en'
        //     ? 'archery, archery score, archery scoring, 3D archery, target shooting, club competition'
        //     : 'boog schieten, boogschieten, boogschiet score, 3D-boogschieten, schietwedstrijden, clubcompetitie',
        description: urlLang == 'en' ? 'Archery Events members portal' : 'Archery Events members leden portaal',
        image:
          urlLang == 'en'
            ? 'https://archery-events.com/assets/img/enog.png'
            : 'https://archery-events.com/assets/img/nlog.png',
        imageAuxData:
          urlLang == 'en'
            ? {
                width: 1200,
                height: 630,
                secureUrl: 'https://archery-events.com/assets/img/enog.png',
                mimeType: 'image/jpeg',
                alt: 'Archery Events',
              }
            : {
                width: 1200,
                height: 630,
                secureUrl: 'https://archery-events.com/assets/img/nlog.png',
                mimeType: 'image/jpeg',
                alt: 'Archery Events',
              },
        url: {
          base: 'https://archery-events.com',
          url: this.router.url,
        },
        type: 'website',
        author: 'Owain van Brakel',
        section: 'Archery',
        twitterCard: 'summary_large_image',
      });
    }

    taskCleanup();
  }

  private titleCase(input: string): string {
    return input
      .toLowerCase()
      .replaceAll('-', ' ')
      .split(' ')
      .map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(' ');
  }
}
