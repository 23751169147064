import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'languageFlag',
  pure: true,
})
export class LanguageFlagPipe implements PipeTransform {
  transform(lang: string | null): string {
    switch (lang) {
      case 'nl':
        return 'nl';
      default:
        return 'us';
    }
  }
}
