import { ChangeDetectionStrategy, Component, inject, OnInit, Signal, viewChild } from '@angular/core';
import { ModalComponent } from '../../modal/modal.component';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';
import { CookiePreferencesStore } from '../../../store/cookie-preferences/cookie-preferences.store';
import { FastSvgComponent } from '@owain/fast-svg/fast-svg.component';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { FormActionDirective } from '@ngneat/error-tailor';
import { SvgRegistry } from '@owain/fast-svg/svg-registry.service';
import { JsonLdService } from '../../../services/seo/json-ld.service';
import { ToasterService } from '@owain/notifier/lib/services/toaster.service';
import { NotificationType } from '@owain/notifier/lib/models/notifications.model';

@Component({
  selector: 'app-cookies-popup-modal',
  templateUrl: './cookies-popup.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ModalComponent, TranslocoPipe, FastSvgComponent, ReactiveFormsModule, FormActionDirective],
})
export class CookiesPopupComponent implements OnInit {
  private readonly svgRegistry: SvgRegistry = inject(SvgRegistry);
  private readonly modal: Signal<ModalComponent<any>> = viewChild.required('modalComponent', { read: ModalComponent });
  private readonly cookiePreferencesStore: InstanceType<typeof CookiePreferencesStore> = inject(CookiePreferencesStore);
  private readonly toastService: ToasterService = inject(ToasterService);
  private readonly translocoService: TranslocoService = inject(TranslocoService);
  private readonly formBuilder: FormBuilder = inject(FormBuilder);
  public cookieForm: FormGroup = this.formBuilder.nonNullable.group({
    googleMaps: [this.cookiePreferencesStore.googleMaps(), [Validators.required]],
    cloudflareInsights: [this.cookiePreferencesStore.cloudflareInsights(), [Validators.required]],
  });
  private readonly jsonLdService: JsonLdService = inject(JsonLdService);

  ngOnInit(): void {
    this.svgRegistry.fetchSvgs('icons/cog');

    this.jsonLdService.addHeroiconImage('icons/cog');

    this.jsonLdService.inject();

    if (!this.cookiePreferencesStore.saved()) {
      this.cookieForm.patchValue({
        googleMaps: true,
        cloudflareInsights: true,
      });
    }
  }

  public saveSelection(event: string): void {
    if (event === 'selection') {
      if (!this.cookiePreferencesStore.saved()) {
        this.cookiePreferencesStore.setSaved(true);
      }

      if (this.cookiePreferencesStore.googleMaps() !== this.cookieForm.value.googleMaps) {
        this.cookiePreferencesStore.setGoogleMaps(this.cookieForm.value.googleMaps);
      }

      if (this.cookiePreferencesStore.cloudflareInsights() !== this.cookieForm.value.cloudflareInsights) {
        this.cookiePreferencesStore.setCloudflareInsights(this.cookieForm.value.cloudflareInsights);
      }
    } else {
      if (!this.cookiePreferencesStore.saved()) {
        this.cookiePreferencesStore.setSaved(true);
      }

      if (!this.cookiePreferencesStore.googleMaps()) {
        this.cookiePreferencesStore.setGoogleMaps(true);
      }

      if (!this.cookiePreferencesStore.cloudflareInsights()) {
        this.cookiePreferencesStore.setCloudflareInsights(true);
      }
    }

    this.toastService.notification({
      alertType: NotificationType.SUCCESS,
      message: this.translocoService.translate('cookiessaved'),
    });

    this.close();
  }

  public close(): void {
    if (this.modal()) {
      this.modal().close();
    }
  }
}
