<div class="container-wrapper">
  <div>
    @if (!hideLayout()) {
      <div class="relative z-40 lg:hidden h-full" role="dialog" aria-modal="true" aria-label="Mobile menu">
        @if (menuOpen()) {
          <div class="fixed inset-0 bg-black bg-opacity-25" @menuBackdrop></div>

          <div class="fixed inset-0 z-40 flex h-full" @menu>
            <div
              class="relative flex w-full max-w-xs flex-col overflow-y-auto bg-white dark:bg-slate-800 pb-12 shadow-xl h-full mobile-inset-top"
              (clickOutside)="outsideMenuClick()">
              <div class="flex px-4 pb-2 pt-5">
                <div class="mobile-inset-left">
                  <button
                    (click)="toggleMenu(true)"
                    type="button"
                    class="relative -m-2 inline-flex items-center justify-center rounded-md p-2 text-gray-600 dark:text-gray-200">
                    <span class="absolute -inset-0.5"></span>
                    <span class="sr-only">Close menu</span>
                    <fast-svg name="icons/menu-close" size="24" />
                  </button>
                </div>
              </div>

              @if (authenticated()) {
                <div class="space-y-6 px-4 py-6">
                  <div class="flow-root mobile-inset-left">
                    <a
                      (click)="toggleMenu(true)"
                      [routerLink]="['/calendar'] | localize"
                      class="-m-2 block p-2 font-medium text-gray-900 dark:text-gray-100">
                      {{ 'calendar' | transloco }}</a>
                  </div>
                </div>

                <div class="space-y-6 border-t border-gray-300 dark:border-gray-500 px-4 py-6">
                  <div class="flow-root mobile-inset-left">
                    <a
                      (click)="toggleMenu(true)"
                      [routerLink]="['/profile', 'settings'] | localize"
                      class="-m-2 block p-2 font-medium text-gray-900 dark:text-gray-100">
                      {{ 'accountsettings' | transloco }}
                    </a>
                  </div>
                </div>

                @if (isAdmin()) {
                  <div class="space-y-6 border-t border-gray-300 dark:border-gray-500 px-4 py-6">
                    <div class="flow-root mobile-inset-left">
                      <a
                        (click)="toggleMenu(true)"
                        [routerLink]="['/admin', 'calendar'] | localize"
                        (prefetch)="prefetchAdmin()"
                        [prefetchMode]="['hover', 'visible']"
                        class="-m-2 block p-2 font-medium text-gray-900 dark:text-gray-100">
                        {{ 'calendar' | transloco }}
                      </a>
                    </div>
                    <div class="flow-root mobile-inset-left">
                      <a
                        (click)="toggleMenu(true)"
                        [routerLink]="['/admin', 'users'] | localize"
                        (prefetch)="prefetchAdmin()"
                        [prefetchMode]="['hover', 'visible']"
                        class="-m-2 block p-2 font-medium text-gray-900 dark:text-gray-100">
                        {{ 'users' | transloco }}
                      </a>
                    </div>
                  </div>
                }

                <div class="space-y-6 border-t border-gray-300 dark:border-gray-500 px-4 py-6">
                  <div class="flow-root mobile-inset-left">
                    <a
                      (click)="toggleMenu(true)"
                      [routerLink]="['/contact'] | localize"
                      class="-m-2 block p-2 font-medium text-gray-900 dark:text-gray-100">
                      {{ 'contact' | transloco }}
                    </a>
                  </div>
                </div>

                <div class="space-y-6 border-t border-gray-300 dark:border-gray-500 px-4 py-6">
                  <div class="flow-root mobile-inset-left">
                    <a
                      (click)="toggleMenu(true)"
                      [routerLink]="['/signout'] | localize"
                      class="-m-2 block p-2 font-medium text-gray-900 dark:text-gray-100">
                      {{ 'signout' | transloco }}
                    </a>
                  </div>
                </div>
              } @else {
                <div class="space-y-6 px-4 py-6">
                  <div class="flow-root mobile-inset-left">
                    <a
                      (click)="toggleMenu(true)"
                      [routerLink]="['/signin'] | localize"
                      class="-m-2 block p-2 font-medium text-gray-900 dark:text-gray-100">
                      {{ 'signin' | transloco }}
                    </a>
                  </div>
                </div>
              }
            </div>
          </div>
        }
      </div>
    }

    <div>
      @if (!hideLayout()) {
        <header class="relative z-1 bg-white/75 dark:bg-slate-800/75 mobile-inset-top">
          <nav aria-label="Top" class="mx-auto px-4 sm:px-6 lg:px-8 border-b border-gray-300 dark:border-gray-500">
            <div class="mobile-inset-left mobile-inset-right">
              <div class="flex h-16 items-center">
                <button
                  (click)="toggleMenu()"
                  type="button"
                  class="relative rounded-md p-2 text-gray-600 dark:text-gray-200 lg:hidden">
                  <span class="absolute -inset-0.5"></span>
                  <span class="sr-only">Open menu</span>
                  <fast-svg name="icons/menu-hamburger" size="24" />
                </button>

                <div class="ml-2 flex lg:ml-0">
                  <a [routerLink]="(authenticated() ? ['/calendar'] : ['/']) | localize">
                    <span class="sr-only">Archery Events</span>
                    @if (theme()) {
                      <fast-svg name="logo/archery-events" size="32" color="#FFFFFF" />
                    } @else {
                      <fast-svg name="logo/archery-events" size="32" color="#000000" />
                    }
                  </a>
                </div>

                <div class="hidden lg:ml-8 lg:block lg:self-stretch">
                  <div class="flex h-full space-x-8">
                    @if (currentUrl() !== '/shell') {
                      @if (authenticated()) {
                        <a
                          [routerLink]="['/calendar'] | localize"
                          class="flex items-center text-sm font-medium text-gray-700 hover:text-gray-800 dark:text-gray-200 dark:hover:text-gray-300">
                          {{ 'calendar' | transloco }}
                        </a>
                      } @else {
                        <a
                          [routerLink]="['/signin'] | localize"
                          class="flex items-center text-sm font-medium text-gray-700 hover:text-gray-800 dark:text-gray-200 dark:hover:text-gray-300">
                          {{ 'signin' | transloco }}
                        </a>
                      }

                      @if (authenticated()) {
                        <a
                          [routerLink]="['/contact'] | localize"
                          class="flex items-center text-sm font-medium text-gray-700 hover:text-gray-800 dark:text-gray-200 dark:hover:text-gray-300">
                          {{ 'contact' | transloco }}
                        </a>
                      }
                    }
                  </div>
                </div>

                <div class="ml-auto flex items-center">
                  @if (authenticated()) {
                    <app-dropdown
                      text="Account"
                      class="hidden lg:flex lg:flex-1 lg:items-center lg:justify-end lg:space-x-6">
                      <div class="py-1" role="none">
                        <a
                          [routerLink]="['/profile', 'settings'] | localize"
                          class="group flex items-center px-4 py-2 text-sm"
                          role="menuitem">
                          <div class="mr-3">
                            <fast-svg name="icons/settings" size="20" />
                          </div>
                          {{ 'accountsettings' | transloco }}
                        </a>
                      </div>

                      @if (isAdmin()) {
                        <div class="py-1" role="none">
                          <a
                            [routerLink]="['/admin', 'calendar'] | localize"
                            class="group flex items-center px-4 py-2 text-sm"
                            role="menuitem">
                            <div class="mr-3">
                              <fast-svg name="icons/calendar" size="20" />
                            </div>
                            {{ 'calendar' | transloco }}
                          </a>
                          <a
                            [routerLink]="['/admin', 'users'] | localize"
                            class="group flex items-center px-4 py-2 text-sm"
                            role="menuitem">
                            <div class="mr-3">
                              <fast-svg name="icons/users" size="20" />
                            </div>
                            {{ 'users' | transloco }}
                          </a>
                        </div>
                      }

                      <div class="py-1" role="none">
                        <a
                          [routerLink]="['/signout'] | localize"
                          class="group flex items-center px-4 py-2 text-sm"
                          role="menuitem">
                          <div class="mr-3">
                            <fast-svg name="icons/sign-out" size="20" />
                          </div>
                          {{ 'signout' | transloco }}
                        </a>
                      </div>
                    </app-dropdown>
                  }

                  <div class="ml-8 flex cursor-pointer">
                    <app-dropdown [button]="false" [inlineBlock]="false">
                      <div
                        slot="start"
                        class="flex items-center text-gray-700 hover:text-gray-800 dark:text-gray-200 dark:hover:text-gray-300 cursor-pointer">
                        @if (language() == 'en') {
                          <fast-svg name="flags/us" size="20" class="block h-auto w-5 flex-shrink-0" />
                        } @else {
                          <fast-svg name="flags/nl" size="20" class="block h-auto w-5 flex-shrink-0" />
                        }
                        <span
                          class="ml-3 block text-sm font-medium text-gray-700 hover:text-gray-800 dark:text-gray-200 dark:hover:text-gray-300 uppercase">
                          {{ language() | languageShort }}
                        </span>
                        <span class="sr-only">, change language</span>
                      </div>
                      @for (lang of ['en', 'nl']; track $index) {
                        <div class="py-1" role="none">
                          <button
                            class="group flex items-center px-4 py-2 text-sm w-full"
                            role="menuitem"
                            (click)="switchLang(lang)">
                            <fast-svg
                              [name]="'flags/' + (lang | languageFlag)"
                              size="20"
                              class="block h-auto w-5 flex-shrink-0" />
                            <span class="ml-3 block text-sm font-medium">{{ lang | language }}</span>
                            <span class="sr-only">, {{ lang | language }}</span>
                          </button>
                        </div>
                      }
                    </app-dropdown>
                  </div>

                  <div class="ml-8 flex">
                    <button (click)="switchTheme()" class="flex items-center filter-white dark:filter-black">
                      @if (theme()) {
                        <fast-svg
                          name="theme/sun"
                          color="#E5E7EB"
                          size="20"
                          class="block h-auto w-5 flex-shrink-0 cursor-pointer text-gray-100" />
                      } @else {
                        <fast-svg
                          name="theme/moon"
                          color="#374151"
                          size="20"
                          class="block h-auto w-5 flex-shrink-0 cursor-pointer text-gray-900" />
                      }
                      <span class="sr-only">, change theme</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </header>
      }

      <router-outlet />
    </div>
  </div>

  @if (!hideLayout()) {
    <app-footer />
  }
</div>

<notifier-container class="w-full" />
<app-scroll-top-top />

<ng-template #notification let-notificationData="notification">
  <div
    class="max-w-sm w-full bg-gray-900 shadow-lg rounded-lg cursor-pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
    <div class="p-4">
      <div class="flex items-start">
        <div class="flex-shrink-0 pt-0.5">
          @if (notificationData.type === 'success') {
            <fast-svg name="icons/checkcircle" size="20" class="block h-5 w-5 text-green-400 mt-3p" />
          } @else if (notificationData.type === 'info') {
            <fast-svg name="icons/infocircle" size="20" class="block h-5 w-5 text-blue-400 mt-3p" />
          } @else if (notificationData.type === 'error') {
            <fast-svg name="icons/xcircle" size="20" class="block h-5 w-5 text-red-400 mt-3p" />
          } @else if (notificationData.type === 'warning') {
            <fast-svg name="icons/exclamationcircle" size="20" class="block h-5 w-5 text-orange-400 mt-3p" />
          }
        </div>
        <div class="ml-3 w-0 flex-1 pt-0.5">
          <p class="text-sm font-medium text-white">
            {{ notificationData.message }}
          </p>
        </div>
        <div class="ml-4 flex-shrink-0 flex">
          <button
            (click)="onClickDismiss(notificationData.id)"
            class="rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <span class="sr-only">Close</span>
            <fast-svg class="block h-5 w-5 text-white mt-3p" name="icons/x" size="20" />
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
