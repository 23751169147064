import { inject } from '@angular/core';
import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { catchError, switchMap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { AuthService } from '../services/http/auth.service';
import { Router } from '@angular/router';
import { LocalizeRouterService } from '@owain/transloco-utils/localize-router.service';

export const jwtRefreshTokenInterceptor: HttpInterceptorFn = (req, next) => {
  const router: Router = inject(Router);
  const localizeRouterService: LocalizeRouterService = inject(LocalizeRouterService);
  const authService: AuthService = inject(AuthService);

  let isRefreshing = false;

  req = req.clone({
    withCredentials: true,
  });

  return next(req).pipe(
    catchError(error => {
      if (error instanceof HttpErrorResponse && !req.url.includes('sign-in') && error.status === 401) {
        if (!isRefreshing) {
          isRefreshing = true;

          return authService.refreshToken().pipe(
            switchMap(user => {
              isRefreshing = false;

              return next(req);
            }),
            catchError(error => {
              isRefreshing = false;

              if (error.status == '403') {
                router.navigateByUrl(<string>localizeRouterService.translateRoute('/signout'));
              }

              return throwError(() => error);
            })
          );
        }

        return next(req);
      }

      return throwError(() => error);
    })
  );
};
